.radioContent {
  position: fixed;
  bottom: 8vh;
  left: 2rem;
  z-index: 1;
}

.radio {
  height: 64px;
  width: 149px;
  opacity: 1;
  border-radius: 6.4px;
  height: 64px;
  width: 149px;
  /* opacity: 0.8; */
  border-radius: 6.4px;
  background: linear-gradient(90deg, #f8f9d2, #e8dbfc) !important;
  opacity: 1 !important;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.107),
    -5px -5px 15px rgba(0, 0, 0, 0.096);
  font-size: 1.2rem !important;
  color: rgb(51, 6, 6) !important;
  font-weight: bold;
  font-family: "Lora";
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  outline: none !important;
  cursor: pointer !important;
}

.radio img {
  position: relative;
  top: -0.2rem;
  margin-right: 0.5rem;
}

/* .audioWave{
    background-color: white !important;
    width: 90%;
    z-index: 10;
    position: absolute;
    bottom: 2rem;
} */

.audioWave {
  position: relative;
  height: 20vh;
  width: 100%;
}

.audio {
  height: 112px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  position: fixed;
  bottom: 0;
  z-index: 10;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.104),
    -5px -5px 15px rgba(0, 0, 0, 0.104);
}

/* .audioWaveWrapper{
      display: flex;
      
  } */
.waveWrapper {
  height: 100%;
}

.title {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex: 2; */
}

.titleImg {
  height: 64px;
  width: 64px;
  opacity: 0.3;
  border-radius: 6.4px;
  background: linear-gradient(90deg,
      rgba(252, 179, 0, 0.66) 0%,
      rgba(255, 217, 124, 0.46) 51.65%,
      #c1554e 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.titleImg img {
  height: 30px;
  filter: contrast(10);
}

.icons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  column-gap: 1.5rem;
  margin-right: 2rem;
}

.icons button {
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
}

.icons button:nth-child(2) {
  background-color: #fcb301;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  /* margin-right: 1rem; */
}

.icons button:first-child {
  border: 1px solid black;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.icons button:nth-child(3) {
  border: 1px solid black;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.icons button:nth-child(2) {
  background-color: #fcb301;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  text-align: center;
}

.icons button:nth-child(2) img {
  height: 16px;
  width: 13px;
  margin-left: 5px;
}

.icons span {
  font-weight: 100 !important;
  font-size: 10px;
}

.icons button img {
  height: 20px;
  width: 20px;
}

.waveWrapper {
  /* width: 100%; */
  height: 100%;
  display: flex;
}

.waveGif {
  height: 100%;
}

.waveGif img {
  height: 100%;
}

.volume {
  flex: 0.2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
  cursor: pointer;
}

.volume img {
  height: 23px;
  width: 24px;
}

.waveform {
  flex: 3;
  height: 20vh;
  margin-top: 1rem;
  overflow: hidden;
}

.pause {
  font-size: 2rem;
  color: white;
}

.volIcon {
  font-size: 1.5rem;
  /* margin-left: 2rem; */
}

.close {
  /* position: absolute; */
  right: 0;
  margin-top: -10rem;
  cursor: pointer;
}

.close img {
  height: 80px;
  width: 80px;
}
.volume2{
  display: none;
}

@media screen and (max-width: 750px) {
  .radioContent {
    position: fixed;
    bottom: 2rem;
    left: 1rem !important;
  }
}

@media screen and (max-width: 540px) {
  .radioContent {
    position: fixed;
    bottom: 2rem;
    left: 1rem !important;
  }

  .volume {
    display: none;
  }

  .volume2{
    /* flex: 0.2; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: 2rem; */
    /* cursor: pointer; */
  }
  .icons span {
    display: none;
  }

  .radio {
    height: 54px !important;
    width: 129px !important;
    opacity: 1;
    border-radius: 6.4px;
    height: 64px;
    width: 149px;
    opacity: 0.3;
    border-radius: 6.4px;
    background: linear-gradient(90deg, #f8f9d2, #e8dbfc) !important;
    opacity: 1 !important;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.107),
      -5px -5px 15px rgba(0, 0, 0, 0.096);
    font-size: 1rem !important;
    color: rgb(51, 6, 6) !important;
    font-weight: bold;
    font-family: "Lora";
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    outline: none !important;
  }

  .radio img {
    position: relative;
    top: -0.2rem;
    margin-right: 0.5rem;
  }


  .audio {
    height: 180px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    position: fixed;
    bottom: 0;
    z-index: 10;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.104),
      -5px -5px 15px rgba(0, 0, 0, 0.104);
  }





  .title {
    margin-top: 2rem;
  }




  .title {
    width: max-content;
    margin-top: 2rem;
  }

  .audioMobile {
    height: 20vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    position: fixed;
    bottom: 0;
    z-index: 10;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.104),
      -5px -5px 15px rgba(0, 0, 0, 0.104);
  }

  .upper {
    display: flex;
    margin-top: 8rem;
    justify-content: space-around;
    box-sizing: border-box;
    width: 100%;
    padding: 0 1rem;
  }

  .close {
    position: absolute;
    right: 0;
    margin-top: -12rem;
  }

  .waveGif {
    display: none;
  }
}