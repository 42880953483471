.audioWrapper {
  height: 100vh;
  width: 100%;
  position: relative;
  font-family: "Lora";
}

.audioWave {
  position: relative;
  height: 20vh;
  width: 100%;
}

.audio {
  height: 20vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: white;
  position: fixed;
  bottom: 0;
  z-index: 10000;
}

/* .audioWaveWrapper{
    display: flex;
    
} */

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
}

.titleImg {
  height: 64px;
  width: 64px;
  opacity: 0.3;
  border-radius: 6.4px;
  background: linear-gradient(
    90deg,
    rgba(252, 179, 0, 0.66) 0%,
    rgba(255, 217, 124, 0.46) 51.65%,
    #c1554e 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.titleImg img {
  height: 30px;
  filter: contrast(10);
}

.icons {
  flex: 2;
  left: 0;
  display: flex;
  position: absolute;
  margin-right: 2rem;
  align-items: center;
  justify-content: space-around;
}

.icons button {
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.icons button:nth-child(2) {
  background-color: rgba(202, 159, 39, 0.947);
  height: 50px;
  width: 50px;
  border-radius: 50%;
  /* margin-right: 1rem; */
}
.icons button:first-child {
  border: 1px solid black;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.icons button:nth-child(3) {
  border: 1px solid black;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.icons button:nth-child(2) {
  background-color: rgba(202, 159, 39, 0.947);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
}

.icons button:nth-child(2) img {
  height: 16px;
  width: 13px;
  margin-left: 5px;
}

.icons span {
  left: 1rem;
  position: relative;
}

.icons button img {
  height: 20px;
  width: 20px;
}

.volume {
  flex: 0.2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 2rem;
}

.volume img {
  height: 23px;
  width: 24px;
}

.waveform {
  flex: 3;
  height: 20vh;
  margin-top: 1rem;
  overflow: hidden;
}

.pause {
  font-size: 2rem;
  color: white;
}
.volIcon {
  font-size: 1.5rem;
  /* margin-left: 2rem; */
}

.close {
  right: 0;
  position: absolute;
  margin-top: -13rem;
}

.close img {
  height: 80px;
  width: 80px;
}

.closeWrapper {
  position: relative;
}

.closeWrapper .close {
  right: 0;
  margin-top: -8rem;
}

.radio {
  height: 64px;
  width: 149px;
  /* opacity: 0.3; */
  border-radius: 6.4px;
  background: linear-gradient(
    90deg,
    rgba(244, 231, 198, 0.66) 0%,
    rgba(250, 249, 247, 0.46) 51.65%,
    #b9adac 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;

  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.074),
    -5px -5px 15px rgba(0, 0, 0, 0.101);
  /* left: 5rem; */
  cursor: pointer;
}

.radioContent {
  position: absolute;
  top: 41rem;
  left: 5rem;
}

.radio span {
  margin-left: 5px;
  font-weight: bold;
}

.radioWrapper {
  position: relative;
  width: 100%;
  height: auto;
}

/* -------------------music player styles-------------------------------- */
.Musicaudio {
  height: 20vh;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #fff;
  position: fixed;
  bottom: 0rem !important;
  z-index: 100;
  bottom: 0;
  justify-content: space-around;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.096),
    -5px -5px 15px rgba(0, 0, 0, 0.079);
}

.MusicaudioMobile {
  display: none;
}

.Musictitle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 2;
}

.actions,
.resDownload {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actions img {
  height: 20px;
  width: 20px;
}

.muIcon {
  font-size: 1.6rem;
}

.musicIcons {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex: 1;
}

.musicIcons button {
  border: none;
  outline: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.musicIcons button:nth-child(2) {
  background-color: rgba(202, 159, 39, 0.947);
  height: 50px;
  width: 50px;
  border-radius: 50%;
}
.musicIcons button:first-child {
  border: 1px solid black;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.musicIcons button:nth-child(3) {
  border: 1px solid black;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}
.musicIcons button:nth-child(2) {
  background-color: rgba(202, 159, 39, 0.947);
  height: 40px;
  width: 40px;
  border-radius: 50%;
  text-align: center;
}

.musicIcons button:nth-child(2) img {
  height: 16px;
  width: 13px;
  margin-left: 5px;
}

.musicIcons button img {
  height: 20px;
  width: 20px;
}

.musicSlider {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconsContainer {
  left: 0;
  z-index: 1;
  width: 9rem;
  position: absolute;
}

/* .musicSlider .range {
  -webkit-appearance: none;
  width: 400px;
  height: 10px;
  background: rgb(209, 204, 204);
  outline: none;
  border-radius: 5px;
  border: none;
  margin: 0 5px;
  position: relative;
}

.musicSlider .range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 10px;
  border-radius: 10px;
  background: rgb(0, 0, 0);
  cursor: pointer;
  z-index: 2;
  position: relative;
}

.range::before {
  content: "";
  height: 10px;
width: var(--music-width);
  background-color: rgb(0, 0, 0);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
} */

/* .range {
  -webkit-appearance:none;
  width:400px;
  height:10px;
  border-radius:15px;
  background:#aaa;
  outline:none;
  overflow:hidden;
}
.range::-webkit-slider-thumb {
  -webkit-appearance:none;
  width:20px;
  height:40px;
  background:#000000;
  box-shadow:-350px 0px 0px 340px #000000;
  cursor:pointer;
  border-radius:100%;

} */

/* -------------------------------music plaery custom styles---------------------- */

.progressBar {
  --bar-bg: #00000021;
  --seek-before-width: 0;
  --seek-before-color: #000000;
  --knobby: #000000;
  --selectedKnobby: #000000;
  appearance: none;
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 400px;
  height: 11px;
  outline: none;
}

/* progress bar - safari */
.progressBar::-webkit-slider-runnable-track {
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 11px;
  outline: none;
}

/* progress bar - firefox */
.progressBar::-moz-range-track {
  background: var(--bar-bg);
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: 11px;
  outline: none;
}

.progressBar::-moz-focus-outer {
  border: 0;
}

/* progress bar - chrome and safari */
.progressBar::before {
  content: "";
  height: 11px;
  width: var(--seek-before-width);
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
}

/* progress bar - firefox */
.progressBar::-moz-range-progress {
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 11px;
}

/* knobby - chrome and safari */
.progressBar::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 11px;
  width: 15px;
  /* border-radius: 50%; */
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  border: none;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  margin: 0px 0 0 0;
  z-index: 3;
  box-sizing: border-box;
}

/* knobby while dragging - chrome and safari */
.progressBar:active::-webkit-slider-thumb {
  transform: scale(1);
  background: var(--selectedKnobby);
}

/* knobby - firefox */
.progressBar::-moz-range-thumb {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: transparent;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  z-index: 3;
  box-sizing: border-box;
}

/* knobby while dragging - firefox */
.progressBar:active::-moz-range-thumb {
  transform: scale(1.2);
  background: var(--selectedKnobby);
}

.Musicaudio .linkRadio {
  flex: 0.2rem;
}

 .LoopRepeat {
  font-size: 1.5rem;
  font-weight: bolder;
 }
 .loopRepeatPosition {
  position: absolute;
  right: -42.5vw;
}


/* ------------------------------ends here------------------------------------------- */

.linkRadio {
  flex: 1;
}

.linkRadio h5 {
  display: flex;
  align-items: center;
  text-decoration: underline;
  font-size: 1rem;
  cursor: pointer;
  font-family: "Lora";
}

.linkRadio h5 img {
  height: 10px;
  margin-right: 10px;
}

.audioMobile {
  display: none;
}
.mobileDownload {
  display: none;
}

/* ---------------------------responsiveness started from here-------------------------- */

@media screen and (max-width: 1300px) {
  .waveform {
    flex: 3;
    height: 20vh;
    margin-top: 1rem;
    overflow: hidden;
  }
  .icons {
    left: calc(100% - 58%);
    bottom: 0.8rem;
    width: fit-content;
  }

  .waveform::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 1300px) {
  /* -------------------music player responsive-------------------------------- */

  .musicSlider .range {
    -webkit-appearance: none;
    width: 300px;
    height: 10px;
    outline: none;
    border: none;
    margin: 0 5px;
    border-radius: 5px;
    position: relative;
    background: rgb(209, 204, 204);
  }

  /* --------------------------------audio player---------------------- */
  .resDownload {
    right: 10vw;
    position: absolute;
    background-color: red;
    bottom: calc(100% - 30vh) !important;
  }
  .waveform {
    flex: 3;
    height: 20vh;
    margin-top: 1rem;
    overflow: hidden;
  }

  .Musictitle {
    padding-top: 2rem;
  }
  .waveform::-webkit-scrollbar {
    display: none;
  }

  .icons {
    left: 0;
    flex: 3.5;
    width: 100%;
    display: flex;
    padding: 0 30vw;
    margin-right: 1rem;
    align-items: center;
    justify-content: space-around;
  }

  .volume {
    flex: 0.2;
    left: 4rem;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
  }

  .volume img {
    height: 23px;
    width: 24px;
  }

  .title {
    margin-left: 1rem;
  }

  .waveform {
    flex: 3;
    height: 20vh;
    margin-top: 1rem;
    overflow: hidden;
    position: relative;
    left: 2rem;
  }

  .radio {
    height: 44px;
    width: 139px;
    /* opacity: 0.3; */
    border-radius: 6.4px;
    background: linear-gradient(
      90deg,
      rgba(244, 231, 198, 0.66) 0%,
      rgba(250, 249, 247, 0.46) 51.65%,
      #b9adac 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    position: relative;
    top: -4rem;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.074),
      -5px -5px 15px rgba(0, 0, 0, 0.101);
    left: 2rem;
    cursor: pointer;
  }

  .radio span {
    margin-left: 5px;
    font-weight: bold;
  }
  .Musicaudio .loopRepeatPosition {
    position: absolute;
    right: 18%;
    bottom: 35%;
  }
  
}

/* ----------------------------------------------1300----------------------------------------- */

@media (min-width: 1000px) and (max-width: 1300px) {
  .Musicaudio .loopRepeatPosition {
    position: absolute;
    right: 18%;
    bottom: 10%;
  }
}
/* ----------------------------------------------1000----------------------------------------- */

@media (min-width: 800px) and (max-width: 1000px) {
  .Musicaudio .loopRepeatPosition {
    position: absolute;
    right: 12%;
    bottom: 10%;
  }
}

/* ----------------------------------------------800----------------------------------------- */

@media (min-width: 600px) and (max-width: 800px) {
  .Musicaudio .loopRepeatPosition {
    position: absolute;
    right: 10%;
    bottom: 10%;
  }
}
/* ----------------------------------------------600----------------------------------------- */

@media (min-width: 540px) and (max-width: 600px) {
  .Musicaudio .loopRepeatPosition {
    position: absolute;
    right: 8%;
    bottom: 10%;
  }
}

/* ----------------------------------------------540----------------------------------------- */

@media (max-width: 540px) {
  .close {
    right: 0;
    position: absolute;
    margin-top: -12rem;
  }
  .Musicaudio {
    height: 22vh;
  }
  .resDownload {
    position: absolute;
    right: 2rem;
    bottom: 3.5vh;
  }
  .audioWrapper {
    height: 70vh;
    width: 100%;
  }

  .musicSlider {
    width: 100vw;
    padding-bottom: 1rem;
  }

  .titleImg {
    height: 34px;
    width: 54px;
    opacity: 0.3;
    border-radius: 6.4px;
    background: linear-gradient(
      90deg,
      rgba(252, 179, 0, 0.66) 0%,
      rgba(255, 217, 124, 0.46) 51.65%,
      #c1554e 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .titleImg img {
    height: 20px;
    filter: contrast(10);
  }

  .title h5 {
    font-size: 0.5rem;
  }
  .title p {
    font-size: 0.5rem;
  }

  .icons span {
    font-size: 0.5rem;
    margin-left: 1rem;
  }

  .icons {
    left: 0;
    bottom: 2rem;
    display: flex;
    padding: 0 32vw;
    align-items: center;
    justify-content: space-around;
  }

  .icons button {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 5px;
  }

  .icons button:nth-child(2) {
    background-color: rgba(202, 159, 39, 0.947);
    height: 40px;
    width: 40px;
    border-radius: 50%;
  }
  .icons button:first-child {
    border: 1px solid black;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }
  .icons button:nth-child(3) {
    border: 1px solid black;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }
  .icons button:nth-child(2) {
    background-color: rgba(202, 159, 39, 0.947);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    text-align: center;
  }

  .icons button:nth-child(2) img {
    height: 10px;
    width: 11px;
    margin-left: 2px;
  }

  .icons button img {
    height: 20px;
    width: 20px;
  }

  .volume {
    position: relative;
    left: 1rem;
  }

  /* ------------------------Radio page style------------------------------------- */

  .radio {
    height: 44px;
    width: 139px;
    /* opacity: 0.3; */
    border-radius: 6.4px;
    background: linear-gradient(
      90deg,
      rgba(244, 231, 198, 0.66) 0%,
      rgba(250, 249, 247, 0.46) 51.65%,
      #b9adac 100%
    );
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    position: relative;
    top: -4rem;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.074),
      -5px -5px 15px rgba(0, 0, 0, 0.101);
    left: 2rem;
    cursor: pointer;
  }

  .radio span {
    margin-left: 5px;
    font-weight: bold;
  }

  .radioWrapper {
    height: 70vh;
    width: 100%;
  }

  /* music player styles */

  .Musicaudio {
    /* display: none; */
    bottom: 0;
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
  }

  .MusicaudioMobile {
    height: 30vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    background-color: white;
    position: fixed;
    bottom: 3rem !important;
    z-index: 10000;
  }

  .MusictitleMobile {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: auto;
    width: 100%;
  }

  .actionsMobile {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .actionsMobile img {
    height: 20px;
    width: 20px;
  }

  .muIconMobile {
    font-size: 1.6rem;
  }

  .musicIconsMobile {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: auto;
  }

  .musicIconsMobile button {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .musicIconsMobile button:nth-child(2) {
    background-color: rgba(202, 159, 39, 0.947);
    height: 50px;
    width: 50px;
    border-radius: 50%;
  }
  .musicIconsMobile button:first-child {
    border: 1px solid black;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }
  .musicIconsMobile button:nth-child(3) {
    border: 1px solid black;
    height: 30px;
    width: 30px;
    border-radius: 50%;
  }
  .musicIconsMobile button:nth-child(2) {
    background-color: rgba(202, 159, 39, 0.947);
    height: 40px;
    width: 40px;
    border-radius: 50%;
    text-align: center;
  }

  .musicIconsMobile button:nth-child(2) img {
    height: 16px;
    width: 13px;
    margin-left: 5px;
  }

  .musicIconsMobile button:nth-child(3) img {
    height: 20px;
    width: 20px;
  }
  .musicIconsMobile button:nth-child(1) img {
    height: 20px;
    width: 20px;
  }

  .musicSliderMobileWrapper {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .musicSliderMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .musicSliderMobile .range {
    -webkit-appearance: none;
    width: 200px;
    height: 10px;
    background: rgb(209, 204, 204);
    outline: none;
    border-radius: 5px;
    border: none;
    margin: 0 5px;
    position: relative;
  }

  .musicSliderMobile .range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 10px;
    border-radius: 10px;
    background: rgb(0, 0, 0);
    cursor: pointer;
    z-index: 2;
    position: relative;
  }

  .musicSliderMobile .range::before {
    content: "";
    height: 10px;
    width: 100px;
    background-color: rgb(0, 0, 0);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
  }

  .linkRadioMobile {
    margin-right: 2rem;
  }

  .linkRadioMobile h5 {
    display: flex;
    align-items: center;
    text-decoration: underline;
    font-size: 0.8rem;
    cursor: pointer;
    white-space: nowrap;
  }

  .linkRadioMobile h5 img {
    height: 5px;
    width: 15px;
    margin-left: 5px;
  }

  .Musicaudio .loopRepeatPosition {
    position: absolute;
    right: 12%;
    bottom: 15%;
  }

}


/* ----------------------------------------------400----------------------------------------- */

@media (min-width: 300px) and (max-width: 400px) {
  .Musicaudio .loopRepeatPosition {
    position: absolute;
    right: 8%;
    bottom: 8%;
  }
}
