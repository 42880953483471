.popupPDFwRAPPER{
    height: 110%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.692);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    /* padding-bottom: 20rem; */
}

.popupPDF{
    background-color: white;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.188), rgba(0, 0, 0, 0.149)-5px 15px black;
margin-top: 0rem;
padding: 2rem 3rem;
border-radius: 10px;
width: 80%;
top: 0;
margin-bottom: 5rem;
}

.pdfHead{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.pdfHead span{
    font-size: 1.5rem;

}
.pdfHead h1{
    font-family: 'Lora';
}

.pdfRenderer{
    height: 550px;
    width: 100%;

}

@media screen and (max-width:1300px) {
    .pdfRenderer{
    height: 480px;
    width: 100%;

}
}