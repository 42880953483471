.header {
  /* background: linear-gradient(90deg, rgba(254,218,0,0) 0%, rgba(252,179,0,0.9) 100%); */
  background: linear-gradient(90deg,
      rgba(254, 106, 0, 0.848) 0%,
      rgba(252, 181, 0, 0.634) 100%);

  color: #573900;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 10vh; */
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  position: relative;
  padding: 0.1rem 0;
}

#search {
  margin: auto;
}

#search-container {
  width: 500px;
  height: 300px;
  margin: auto;
}

#googlesearch form {
  display: none;
  border: 2px solid red;
}


.headerWrapper {
  /* height: 15vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.update {
  height: 5vh;
  width: 100%;
  background-color: rgba(193, 85, 78, 0.93);
  color: white;
}

.header a {
  color: #573900;
  text-decoration: none;
  font-family: "Lora";
  font-weight: bold;
}

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5rem;
}

.UserIconImage {
  background-color: white;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  /* box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.286),-5px -5px 15px rgba(0, 0, 0, 0.221); */
}

.dropdownUserIcon {
  background-color: rgb(33, 19, 19);
  height: 50px !important;
  width: 50px !important;
  border-radius: 100%;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.145),
    -5px -5px 15px rgba(0, 0, 0, 0.09) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 1rem;
}

.logo {
  margin-right: 4rem;
}

.logo img {
  height: 70px;
  width: 70px;
  top: 0.2rem;
  position: relative;
  cursor: pointer;
}

.login a {
  padding: 0 1rem;
  text-align: center;
  font-family: "Lora";
  font-size: 12px;
}

.login span {
  font-size: 12px;
}

.icon {
  top: 0.05rem;
  /* position: absolute; */
  position: relative;
}

.links {
  display: flex;
}

.links a {
  padding: 0 0.2rem;
  margin: 0 5px;
  font-size: 12px;
}

.linkOne,
.linkTwo,
.linkThree,
.linkFour,
.linkFive,
.linkSix,
.linkSeven,
.linkEight {
  position: relative;
  padding: 1rem 0 !important;
  text-transform: uppercase;
}

.linkOne:hover~.dOne {
  display: grid;
}

.linkTwo:hover~.dTwo {
  display: grid;
}

.linkThree:hover~.dFour {
  display: grid;
}

.linkFour:hover~.dtemples {
  display: flex;
}

.linkFive:hover~.dFive {
  display: flex;
}

.linkSix:hover~.dSix {
  display: grid;
}

.linkSeven:hover~.dSeven {
  display: flex;
}

.linkEight:hover~.dEight {
  display: flex;
}

.linkOne:hover {
  color: white !important;
}

.linkTwo:hover {
  color: white !important;
}

.linkThree:hover {
  color: white !important;
}

.linkFour:hover {
  color: white !important;
}

.linkFive:hover {
  color: white !important;
}

.linkSix:hover {
  color: white !important;
}

.linkSeven:hover {
  color: white !important;
}

.linkEight:hover {
  color: white !important;
}

.dOne:hover {
  display: grid;
}

.dTwo:hover {
  display: grid;
}

.dThree:hover {
  display: grid;
}

.dFour:hover {
  display: grid;
}

.dFive:hover {
  display: flex;
}

.dSix:hover {
  display: grid;
}

.dSeven:hover {
  display: flex;
}

.dEight:hover {
  display: flex;
}

.dOne {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: absolute;
  height: auto;
  width: auto;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
  display: none;
  /* border-radius: 10px; */
  font-size: 0.8rem;
  white-space: nowrap;
  font-weight: bold;
  padding: 1rem;
}

.subDone {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  cursor: pointer;
}

.subDone h3 {
  color: #505050;
  font-family: Lora;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin: 0;
  padding: 0.3rem;
}

.subDone h6 {
  color: #505050;
  font-family: Lora;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0;
}

.pratice::after {
  content: "";
  position: absolute;
  height: 1.5px;
  width: 24px;
  background-color: #573901;
  opacity: 0.3;
  top: 2.3rem;
  margin-left: -10rem !important;
}

.phil::after {
  content: "";
  position: absolute;
  height: 1.5px;
  width: 24px;
  background-color: #573901;
  opacity: 0.3;
  top: 2.3rem;
  margin-left: -5.8rem !important;
}

.subDone h4 {
  color: #505050 !important;
  font-family: Lora;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  cursor: pointer;
}

.subDone p {
  color: #00000065 !important;
  font-family: Lora;
  margin-top: 5px;

  /* font-weight: 200 !important; */
}

.dTwo {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: absolute;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
  display: none;
  font-size: 0.8rem;
  white-space: nowrap;
  font-weight: bold;
  font-size: 0.8rem;
  white-space: nowrap;
  font-weight: bold;
  padding: 1rem;
}

.subDtwo {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin: 0.4rem 0;
}

.subDtwo h3 {
  color: #505050;
  font-family: Lora;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 25px;
  margin: 0;
  padding-left: 0.6rem;
  position: relative;
  left: -0.6rem;
}

.subDtwo h6 {
  color: #505050;
  font-family: Lora;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin: 0;
}

.sublit::after {
  content: "";
  position: absolute;
  height: 1.5px;
  width: 24px;
  background-color: #573901;
  opacity: 0.3;

  top: 2.2rem;
  margin-left: -4.8rem;
}

.subQuiz::after {
  content: "";
  position: absolute;
  height: 1.5px;
  width: 24px;
  background-color: #573901;
  opacity: 0.3;

  top: 2.3rem;
  margin-left: -2rem;
}

.subSid::after {
  content: "";
  position: absolute;
  height: 1.5px;
  width: 24px;
  background-color: #573901;
  opacity: 0.3;

  top: 2.2rem;
  margin-left: -10.8rem;
}

.subVegan::after {
  content: "";
  position: absolute;
  height: 1.5px;
  width: 24px;
  background-color: #573901;
  opacity: 0.3;

  top: 2.4rem;
  margin-left: -5rem;
}

.subfom::after {
  content: "";
  position: absolute;
  height: 1.5px;
  width: 24px;
  background-color: #573901;
  opacity: 0.3;
  top: 2.2rem;
  margin-left: -6.2rem;
}

.subDtwo h4 {
  color: #505050 !important;
  font-family: Lora;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  cursor: pointer;
}

.subDtwo p {
  color: #00000065 !important;
  font-family: Lora;
  margin-top: 5px;
}

.dFour {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: absolute;

  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
  display: none;
  font-size: 0.8rem;
  white-space: nowrap;
  font-weight: bold;
  padding: 0rem 2rem;
  box-sizing: border-box;
  padding-bottom: 0.7rem;
}

.subThree {
  display: flex;
  flex-direction: column;
  padding: 0rem 0.6rem;
  /* padding: 0; */
  margin-top: 0rem;
  cursor: pointer;
}

.underPara {
  position: relative;
  top: -1rem;
}

.subThree h3 {
  color: #505050;
  font-family: Lora;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
  /* margin: 0 !important; */
}

/* .subThree h5{
  color: #505050;
  font-family: Lora;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin: .5rem 0;
  padding: .3rem 0;
  padding-left: 1rem;
  position: relative;
  left: -1rem;
} */

/* .subThree h4{
  color: #505050 !important;
  font-family: Lora;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  cursor: pointer;
  margin: 0 !important;
} */

.subThree p {
  color: #0000006d !important;
  font-family: Lora;
  margin-top: 0rem;
  font-size: 1rem;
}

.dtempleswrapper {
  position: relative;
}

.dtemples {
  position: absolute;
  top: 1.3rem;
  left: 0;
  background-color: white;
  box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
  width: 180px;
  display: flex;
  align-items: flex-start;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 4px;
  padding: 1rem;
  box-sizing: border-box;
  display: none;
}

.dtemples p {
  cursor: pointer;
}

.dFive {
  /* display: grid;
grid-template-columns: repeat(4,1fr); */
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  position: absolute;
  height: auto;
  width: auto;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
  display: none;
  font-size: 0.8rem;
  white-space: nowrap;
  font-weight: bold;
  z-index: 10;
  left: 10rem;
  /* right: 5rem; */
  padding: 1rem;
  box-sizing: border-box;
  padding-bottom: 1rem;
}

.SubMenuWrapperfive {
  display: flex;
  flex: 6;
  /* flex-direction: column; */
}

.mainContent {
  margin: 0 0.2rem;
}

.SubMenuWrapperfive p {
  margin: 0.5rem 0.3rem;
}

.SubMenuWrapperfive h5 {
  margin: 0 0.3rem !important;
}

.mainMenuWrapper {
  margin: 0 1rem;
  flex: 1;
}

.paraMenu {
  display: flex !important;
  flex-direction: column;
}

.frenchWrapper {
  width: 100%;
  position: relative;
  /* margin: 0 1rem; */
}

.frenchWrapper h6::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 1.4px !important;
  background-color: #573901;
  opacity: 0.3;
  /* top: 1rem; */
  margin-top: 1.36rem;
  left: 2rem;
}

.Duetsch {
  position: relative;
  /* margin: 0 1rem; */
}

.Duetsch h6::after {
  content: "";
  position: absolute;
  width: 20px !important;
  height: 1.4px !important;
  background-color: #573901;
  opacity: 0.3;
  /* top: 1rem; */
  margin-top: 1.3rem;
  left: 2rem;
}

.engWrapper {
  position: relative;
  width: 100%;
  /* margin: 0 1rem; */
}

.engWrapper h6::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 1.4px !important;
  background-color: #573901;
  opacity: 0.3;
  /* top: 1rem; */
  margin-top: 1.36rem;
  left: 0rem;
}

.gujratWrapper {
  width: 100%;
  position: relative;
  /* margin: 0 1rem; */
}

.gujratWrapper h6::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 1.4px !important;
  background-color: #573901;
  opacity: 0.3;
  /* top: 1rem; */
  margin-top: 1.36rem;
  left: 2rem;
}

.marthiWrapper {
  width: 100%;
  position: relative;
  /* margin: 0 1rem; */
}

.marthiWrapper h6::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 1.4px !important;
  background-color: #573901;
  opacity: 0.3;
  /* top: 1rem; */
  margin-top: 1.36rem;
  left: 2rem;
}

.stotras {
  width: 100%;
  position: relative;
  /* margin: 0 1rem; */
}

.stotras h6::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 1.4px !important;
  background-color: #573901;
  opacity: 0.3;
  /* top: 1rem; */
  margin-top: 1.36rem;
  left: 2rem;
}

.puraWrapper {
  width: 100%;
}

.puraInnerWrapper {
  width: 100%;
}

.puraInnerWrapper p {
  position: relative;
  left: -2rem;
}

.dsixItems {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.puranas h6::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 1.7px !important;
  background-color: #573901;
  opacity: 0.3;
  /* top: 1rem; */
  margin-top: 1.36rem;
  left: 0.6rem;
}

.hind h6::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 1.7px !important;
  background-color: #573901;
  opacity: 0.3;
  margin-top: 1.36rem;
  left: 2rem;
}

.Vachana h6::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 1.7px !important;
  background-color: #573901;
  opacity: 0.3;
  /* top: 1rem; */
  margin-top: 1.36rem;
  left: 2rem;
}

.sansK {
  width: 100%;
}

.ActiveLink {
  border-radius: 4px;
  background-color: #fff7e4 !important;
  padding: 0.5rem 1rem;
  width: 100%;
}

.subActiveLink {
  border-radius: 4px;
  background-color: #fff7e4 !important;
  /* padding: .5rem 1rem;  */
  /* padding-left: .6rem; */
  /* padding: .6rem 0.2rem; */
  width: 100%;
}

.subActiveLinkMobile {
  border-radius: 4px;
  background-color: #fff7e4 !important;
  padding: 0.6rem 0.2rem;
  width: 100%;
}

.dFive h5 {
  margin: 0;
  margin-top: 0.5rem;
  color: #505050;
  font-family: Lora;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}

/* .dFive h6::after{
  content: '';
  height: 1.5px;
  width: 40px;
  background-color: #573901;
} */

/* .dFour a {
  color: #505050;
  font-family: Lora;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
} */

.puranas {
  width: 120%;
  padding: 0.5rem;
  position: relative;
  left: -0.5rem;
  cursor: pointer;
}

.dFive p {
  color: #818181;
  font-family: Lora;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 15px;
  margin-top: 0.4rem;
  font-weight: 100;
}

.dFive h4 {
  color: #505050;
  font-family: Lora;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
  margin: 1rem 0;
  padding: 0.5rem;
  position: relative;
  /* top: ; */
  left: -0.5rem;
  /* margin-right: 2rem; */
  cursor: pointer;
}

.dSix {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  place-items: start;
  justify-items: flex-start;
  padding: 1rem;
  justify-items: flex-start;
  position: absolute;
  height: auto;
  width: auto;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
  display: none;
  /* border-radius: 10px; */
  font-size: 0.8rem;
  white-space: nowrap;
  font-weight: bold;
}

.dFive a {
  color: #505050;
  font-family: Lora;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
}

.dSix h5 {
  color: #505050;
  font-family: Lora;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
  margin: 0;
}

.dsixItems {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.dSix p {
  font-family: Lora;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  font-size: 1rem !important;
  margin: 0.3rem 0 !important;
  padding-top: 0.1rem;
  color: #505050 !important;
  cursor: pointer;
}

.dSeven {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  position: absolute;
  height: auto;
  width: auto;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
  display: none;
  font-size: 0.8rem;
  white-space: nowrap;
  font-weight: bold;
  padding: 1rem;
}

.dSix a {
  color: #505050;
  font-family: Lora;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  padding-top: 0rem;
  padding-left: 0rem;
  width: 100%;
}

.dSeven p {
  color: #505050 !important;
  font-family: Lora;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 18px;
  /* height: 50px; */
  width: 100%;
  margin: 0;
  padding: 0.2rem 0;
  cursor: pointer;
  margin-left: 0.3rem;
}

.dSeven h5 {
  color: #505050 !important;
  margin: 5px;
}

.dEight {
  align-items: flex-start;
  flex-direction: column;
  position: absolute;
  height: auto;
  max-width: 80vw;
  width: fit-content;
  display: none;
  z-index: 2;
  padding: 1rem;
  font-size: 0.8rem;
  border-radius: 4px;
  font-weight: bold;
  /* white-space: nowrap; */
  background-color: #ffffff;
  /* justify-content: space-around; */
  box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
}

.dEight p {
  font-size: 14px;
  cursor: pointer;
  margin: 0.2rem 0;
  font-weight: 600;
  color: #505050 !important;
}

.dEight a {
  font-size: 14px;
  cursor: pointer;
  margin: 0.2rem 0 !important;
  font-weight: 600;
  color: #505050 !important;
  width: 100% !important;
  position: relative;
  left: -1.2rem !important;
}

.login {
  position: relative;
}

.login .imgWrapper {
  /* background-color: white; */
  padding: 7px 10px;
  border-radius: 50%;
  margin-top: 10px;
  /* position: relative; */
  margin-left: 1rem;
}

.imgWrapper img {
  height: 24px;
  width: 24px;
}

.header a:hover {
  color: #573900;
}

.searchIcon {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.725);
  margin-top: 0.5rem;
  margin-left: 1rem;
  position: relative;
  left: -0.4rem;
}

.searchIconWrapper {
  padding: 0.2rem;
  border-radius: 100%;
  position: relative;
  margin-left: 5px;
}

.searchcontentWrapper {
  position: absolute;
  top: 3.5rem;
  background-color: white;
  right: 0rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
  box-sizing: border-box;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.056),
    -5px -5px 10px rgba(0, 0, 0, 0.018);
}

.searchcontentWrapper input {
  background-color: white !important;
  opacity: 1;
  width: 370px !important;
  color: black !important;
  /* border: none !important; */
  outline: none !important;
}

.searchInputWrapperContent {
  width: 100%;
  position: relative;
}

.searchInputWrapperContent button {
  position: absolute;
  outline: none;
  border: none;
  background-color: transparent;
  right: 0.5rem;
  top: 0.5rem;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.348);
}

.slider {
  padding: 1rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.178);
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  width: 100%;
  /* background-color: red; */
  position: relative;
}

.slider button {
  font-size: 1rem !important;
  color: black !important;
  font-family: "Lora" !important;
  margin: 0;
  padding: 0 !important;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.topBtn {
  margin-left: 30px !important;
}

.spanWrapper {
  position: absolute;
  top: 0;
}

.spanWrapper .oneSpan {
  width: 130px !important;
  height: 3px;
  background-color: black;
  position: absolute;
  top: 3rem;
  border-radius: 10px;
}

.contentOFSearch {
  width: 100%;
  margin-top: 1rem;
}

.contentOFSearch h4 {
  font-family: "Lora";
  color: black !important;
  padding: 0.2rem 0;
  cursor: pointer;
}

.contentOFSearch h4 img {
  height: 20px;
}

.langwrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  height: 170px;
  width: 150px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  top: 4rem;
  z-index: 2;
  /* right: 1rem; */
  left: 6.4rem;
  border-radius: 6px;
  display: none;
  box-sizing: border-box;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.104);
}

.langwrapper .change {
  background-color: rgba(252, 181, 0, 0.348);
  width: 100%;
  padding: 10px 0;
  text-align: center;
  font-weight: 500;
}

.active {
  color: #fcb300;
  font-weight: bold;
  font-size: 1rem;
}

.langOpt {
  padding-left: 1.4rem;
}

.langwrapper p {
  cursor: pointer;
  font-weight: bold;
}

.updateWrapper {
  background-color: rgba(193, 85, 78, 0.93);
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 10vh; */
  width: 100%;
  /* top: -10.1rem; */
  position: relative;
  color: rgb(255, 255, 255);
  padding: 0 0 0 1rem;
  z-index: 1;
  padding: 0.7rem 0;
}

.left h2 {
  font-size: 12px;
  white-space: nowrap;
  font-family: "Lora";
}

.left {
  flex: 2.5;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.right {
  flex: 9;
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.right span {
  font-weight: 100;
  /* font-weight: lighter !important; */
  font-size: 12px;
  color: white;
  text-align: center;
  white-space: nowrap;
  transform: translateX(100%);
  position: relative;
  animation: scroll 60s linear infinite;
}

.mobileUserProfile {
  display: none;
}

.profile {
  position: relative;
}

.profile img {
  height: 40px;
  width: 40px;
  border-radius: 100%;
  margin-right: 0.6rem;
  object-fit: cover;
}

.profile p {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #573900;
  font-weight: 600;
  cursor: pointer;
}

.profile span {
  /* color: white !important; */
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 5px;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: white;
}

.profileDropDown {
  position: absolute;
  /* height: 305px; */
  width: 280px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
  top: 4rem;
  /* left: -6rem; */
  right: 12rem;
  padding: 1rem;
}

.notiBell {
  font-size: 1.2rem;
  margin-right: 1rem;
}

.notiMain {
  width: 100%;
  display: flex;
  justify-content: space-between !important;
}

.noti {
  display: flex;
  align-items: center;
  color: #00000038 !important;
  font-weight: 50 !important;
}

.notiCount {
  height: 20px;
  width: 20px;
  background-color: red;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
  font-size: 10px !important;
  /* flex-direction:column; */
}

.profileDropDown h3 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: "Lora";
  font-size: 1.2rem;
  height: 5vh;
}

.profileDropDown h3 img {
  height: 30px;
  width: 30px;
  border-radius: 100%;
  margin-right: 0.6rem;
  object-fit: cover;
}

.profileDropDown hr {
  outline: none;
  border: none;
  background-color: transparent;
  background-color: rgba(0, 0, 0, 0.144);
  height: 2px;
  margin-bottom: 1rem;
}

.profileDropDown h3 span {
  font-size: 2rem;
  background-color: white;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.7);
  margin-right: 1rem;
}

.profileDropDown p {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.profileDropDown p span {
  margin-right: 1rem;
  margin-left: 1rem;
  color: rgba(0, 0, 0, 0.397);
  font-size: 1rem;
  margin-top: 4px;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.right p {
  font-weight: lighter;
  font-size: 13px;
  color: white;
  text-align: center;
  list-style: square;
}

.hamburger {
  display: none;
}

.hamburger button {
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 1.7rem;
  margin-right: 2rem;
  color: rgb(139, 1, 1) !important;
}

.MobileMenu {
  height: auto;
  width: 100%;
  background: white;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  color: white;
  flex-direction: column;
  position: fixed;
  z-index: 222222;
  left: 0;
  top: 0;
  bottom: 0;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 1rem;
  padding-bottom: 0rem;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.193);
}

.MobileMenu::-webkit-scrollbar {
  width: 2px;
  background-color: rgb(0, 0, 0);
}

.MobileMenu hr {
  width: 100%;
  outline: none;
  border: none;
  height: 1.5px;
  background-color: rgba(0, 0, 0, 0.473);
}

.MobileMenu a {
  text-transform: uppercase;
}

.submenuItem {
  font-size: 13px !important;
  position: relative;
  left: 0.8rem;
}

.mobiCON {
  color: black;
  font-size: 2.6rem;
}

.mobLogo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 1rem 0 !important;
}

.mobLogo img {
  height: 50px !important;
  width: 50px !important;
  margin-left: -2rem !important;
}

.mobDropDownLink {
  width: 100%;
  height: auto;
  padding: 0.7rem 0;
}

.mobDropDownLink p {
  color: #573900;
  font-family: Lora;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin: 1rem 0;
  cursor: pointer;
}

.mobDropDownLink h6 {
  color: #573900;
  font-family: Lora;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  width: 100%;
  cursor: pointer;
}

.mobDropDownLink h4 {
  color: #573900;
  font-family: Lora;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  /* margin: 1rem 0; */
  width: 100%;
  cursor: pointer;
  /* display: flex; */
  /* justify-content: space-between; */
}

.mobInput {
  width: 100% !important;
  display: flex;
}

.mobInput button {
  margin-left: 0.6rem;
  background-color: #fcb300;
  outline: none;
  border: none;
  padding: 0 1rem;
  font-family: "Lora";
  color: #573900;
  font-weight: 600;
  border-radius: 4px;
}

.mobInput input[type="text"] {
  width: 100% !important;
  background-color: white !important;
  opacity: 1 !important;
  border: 1px solid rgba(0, 0, 0, 0.205) !important;
  height: 40px;
  color: black !important;
}

.mobLangWrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  position: relative;
  left: 0rem;
  /* background-color: red !important; */
  margin: 1rem 0;
  width: 100%;
}

.langImg {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.langImg .langImgSpan {
  color: #573900;
  font-family: Lora;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
}

.MobileMenu a {
  color: #573900;
  font-family: Lora;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  margin: 1rem 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.MobileMenu span {
  color: #573900 !important;
  font-family: "Lora";
  font-size: 15px;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 18px;
  margin: 1rem 0;
  /* opacity: 0.8; */
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.mobLang {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 0rem;
  position: relative;
  left: 0rem;
}

.closeLoginBtn {
  display: flex;
  align-items: center;
}

.closeLoginButton {
  outline: none;
  border: 1px solid #573900;
  background-color: transparent;
  font-family: "Lora";
  color: #573900 !important;
  font-weight: 900;
  position: relative;
  right: 2rem;
  height: 32px;
  width: 81px;
  border-radius: 4px;
}

.mobLang button {
  font-size: 1rem !important;
  color: #573900 !important;
  /* margin: 0rem !important; */
  padding: 0 !important;
  font-family: "Lora";
  font-weight: bold;
  border: none !important;
  outline: none !important;
  background-color: transparent;
  margin-top: 1rem;
}

.activeMobileLang {
  border-radius: 4px;
  background-color: #fcb300 !important;
  height: 32px;
  width: 98px;
}

.notificationWrapper {
  overflow: scroll;
  width: 100%;
  /* height: 200vh; */
  height: auto;
  position: fixed;
  top: 0rem;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.633);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 2;
  padding-bottom: 50rem;
}

.notWrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  transition: all ease 0.7s;
}

.notification {
  /* height: 500px; */
  width: 500px;
  background-color: white;
  margin-top: 10rem;
  box-sizing: border-box;
  padding: 2rem 1rem;
}

.notification h4 button {
  padding: 0.3rem 0.3rem;
  background-color: orange;
  border-radius: 100%;
  position: static;
  margin-right: 10px;
  outline: none !important;
  border: none !important;
}

.notification h4 {
  display: flex;
  align-items: center;
}

.hWrpr {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.hWrpr h1 {
  font-size: 1.5rem !important;
  margin: 0;
}

.hWrpr span {
  font-size: 1.7rem;
  cursor: pointer;
  font-weight: 100 !important;
}

.hWrpr p {
  color: #505050;
  font-family: Lora;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0;
}

.scnd {
  border-radius: 4px;
  background-color: #fff7e4;
  padding: 1rem 1rem;
  margin-top: 1rem;
}

.fve {
  border-radius: 4px;
  padding: 1rem 1rem;
  margin-top: 1rem;
}

.frth {
  border-radius: 4px;
  padding: 1rem 1rem;
  margin-top: 1rem;
}

.thrd {
  border-radius: 4px;
  padding: 1rem 1rem;
  margin-top: 1rem;
}

.scnd span {
  height: 7px;
  width: 7px;
  border-radius: 100%;
  background-color: red;
}

.notification h4 {
  font-family: "Lora";
}

.sx {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.sx h4 {
  color: #fcb300;
  font-family: Lora;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  text-align: right;
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  .dFive {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    position: absolute;
    height: auto;
    width: auto;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
    display: none;
    font-size: 0.8rem;
    white-space: nowrap;
    font-weight: bold;
    z-index: 10;
    left: 5rem !important;
    padding: 1rem;
    box-sizing: border-box;
    padding-bottom: 1rem;
  }
}

/*--------------------------- header responsiveness started from here-------------------------------------- */
@media screen and (max-width: 1300px) {
  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 2rem;
  }

  .logo {
    margin-right: 2rem;
  }

  .left {
    flex: 2;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .dFive {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    position: absolute;
    height: auto;
    /* width: auto; */
    min-width: 450px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
    display: none;
    font-size: 0.8rem;
    white-space: nowrap;
    font-weight: bold;
    z-index: 10;
    left: 5rem !important;
    padding: 1rem;
    box-sizing: border-box;
    padding-bottom: 1rem;
  }

  .SubMenuWrapperfive {
    display: flex;
    flex: 6;
    /* flex-direction: column; */
  }

  .mainContent {
    margin: 0rem;
  }

  .SubMenuWrapperfive p {
    margin: 0.5rem 0.3rem;
  }

  .SubMenuWrapperfive h5 {
    margin: 0 0.3rem !important;
  }

  .mainMenuWrapper {
    margin: 0 0.5rem;
    flex: 1;
    margin-top: -0.7rem;
  }

  .dEight a {
    font-size: 14px;
    cursor: pointer;
    margin: 0.2rem 0 !important;
    font-weight: 600;
    color: #505050 !important;
    width: 100% !important;
    position: relative;
    left: 0rem !important;
  }

  .links a {
    font-size: 0.7rem !important;
  }
}

/* ------------------------------------------------------------------------------------------------------ */

@media screen and (max-width: 1100px) {
  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 0 2rem; */
    /* background-color: blue; */
  }

  .login a {
    padding: 0 1rem;
    text-align: center;
    font-family: "Lora";
  }

  .logo {
    padding: 0 1rem;
  }

  .header .links a {
    font-size: 0.8rem;
    padding: 0;
    margin: 0.4rem;
  }

  .login a {
    font-size: 0.8rem;
  }

  .logo img {
    height: 60px;
    width: 60px;
    top: 0.2rem;
    position: relative;
  }

  .langwrapper {
    display: flex;
    align-items: center;
    /* justify-content: flex-start; */
    flex-direction: column;
    height: 170px;
    width: 130px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 4.5rem;
    z-index: 2;
    right: 0.5rem;
    border-radius: 6px;
    display: none;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.104);
  }

  .update {
    height: 2vh;
    width: 100%;
    background-color: red;
    color: white;
  }

  .left h2 {
    font-size: 0.8rem;
    white-space: nowrap;
  }

  .dOne {
    position: absolute;
    height: 200px;
    width: 110px;
    background: white;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.105);
    /* left: 6rem; */
    display: none;
    /* border-radius: 10px; */
    font-size: 0.8rem;
    white-space: wrap;
    font-weight: bold;
    text-align: center;
  }

  .dTwo {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    position: absolute;
    height: 200px;
    width: 117px;
    background: white;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.105);
    /* border-radius: 10px; */
    /* left: 11rem; */
    display: none;
    font-size: 0.8rem;
    white-space: wrap;
    font-weight: bold;
    text-align: center;
  }

  .dropThee {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    position: absolute;
    height: 200px;
    width: 140px;
    background: white;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.105);
    /* border-radius: 10px; */
    /* left: 16rem; */
    display: none;
    font-size: 0.8rem;
    white-space: wrap;
    font-weight: bold;
    text-align: center;
  }

  .dFour {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    position: absolute;
    height: 200px;
    width: 120px;
    background: white;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.105);
    /* border-radius: 10px; */
    /* left: 25.4rem; */
    display: none;
    font-size: 0.8rem;
    white-space: wrap;
    font-weight: bold;
    text-align: center;
  }

  .dFive {
    position: absolute;
    height: 200px;
    width: 120px;
    background: white;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.105);
    /* border-radius: 10px; */
    /* left: 30rem; */
    display: none;
    font-size: 0.8rem;
    white-space: wrap;
    text-align: center;
    font-weight: bold;
  }

  .dSix {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    position: absolute;
    height: 250px;
    width: 130px;
    background: white;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.105);
    /* right: 29rem; */
    display: none;
    /* border-radius: 10px; */
    font-size: 0.8rem;
    white-space: wrap;
    font-weight: bold;
    text-align: center;
  }

  .dSeven {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    position: absolute;
    height: 200px;
    width: 120px;
    background: white;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.105);
    /* right: 23rem; */
    display: none;
    font-size: 0.8rem;
    white-space: wrap;
    font-weight: bold;
    text-align: center;
  }
}

@media screen and (max-width: 770px) {
  .hamburger {
    display: flex;
    margin-right: 1rem;
  }

  .mobileUserProfile {
    display: flex;
    /* position: relative; */
  }

  .inputbox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
    /* height: auto; */
  }

  .yellowBtn {
    border: none;
    /* outline: none; */
    background-color: transparent;
    /* font-size: 0.2rem; */
    /* color: rgb(196, 196, 196) !important; */
    position: relative;
    right: 15%;
    z-index: 1;
  }


  .profileMobileDropdownWrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    height: 100%;
    background-color: rgba(10, 10, 10, 0.623);

    /* box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.303); */
  }

  .profileMobileDropdown {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    right: 0;
    height: 350px;
    background-color: white;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.303);
    padding: 2rem;
    box-sizing: border-box;
  }

  .closeDragbtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .closeDragbtn button {
    width: 50px;
    height: 7px;
    outline: none;
    border: none;
    background-color: #505050;
    border-radius: 10px;
    position: relative;
    top: -1rem;
  }

  .notiBell {
    font-size: 1.2rem;
    margin-right: 1rem;
  }

  .notiMain {
    width: 100%;
    display: flex;
    justify-content: space-between !important;
    margin-top: 1rem;
  }

  .noti {
    display: flex;
    align-items: center;
    color: #50505039 !important;
    font-weight: normal !important;
  }

  .notiCount {
    height: 20px;
    width: 20px;
    background-color: red;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white !important;
    font-size: 10px !important;
    /* flex-direction:column; */
  }

  .profileMobileDropdown h3 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: "Lora";
    font-size: 1.2rem;
    height: 7vh;
  }

  .profileMobileDropdown h3 img {
    height: 30px;
    width: 30px;
    border-radius: 100%;
    margin-right: 0.6rem;
    object-fit: cover;
  }

  .profileMobileDropdown hr {
    outline: none;
    border: none;
    background-color: transparent;
    background-color: rgba(0, 0, 0, 0.144);
    height: 2px;
    /* margin-bottom: 0rem !important; */
  }

  .profileMobileDropdown h3 span {
    font-size: 2rem;
    background-color: white;
    height: 40px;
    width: 40px;
    border-radius: 100%;
    box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.7);
  }

  .profileMobileDropdown p {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #505050;
    font-size: 14px !important;
    cursor: pointer;
    line-height: 28px;
  }

  .profileMobileDropdown p span {
    margin-right: 1rem;
    margin-left: 1rem;
    color: rgba(0, 0, 0, 0.397);
    font-size: 1rem;
    margin-top: 4px;
  }

  .mobileUserProfile .profile {
    left: 3rem;
  }

  .logo {
    margin-left: 1rem;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    /* display: none; */
  }

  .headerWrapper {
    height: 15vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    /* margin-top: -26.6rem; */
  }

  .update {
    height: 5vh;
    width: 100%;
    background-color: rgba(193, 85, 78, 0.93);
    color: white;
  }

  .header a {
    color: #573900;
    text-decoration: none;
    font-family: "Lora";
    font-weight: bold;
  }

  .login {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5rem;
  }

  .logo {
    margin-right: 4rem;
  }

  .logo img {
    height: 70px;
    width: 70px;
    top: 0.2rem;
    position: relative;
  }

  .login a {
    padding: 0 1rem;
    text-align: center;
    font-family: "Lora";
    font-size: 12px;
  }

  .login span {
    font-size: 12px;
  }

  .icon {
    top: 0.1rem;
    /* position: absolute; */
    position: relative;
  }

  .links {
    display: none;
  }

  .links a {
    padding: 0 0.2rem;
    margin: 0 5px;
    font-size: 12px;
  }

  .linkOne,
  .linkTwo,
  .linkThree,
  .linkFour,
  .linkFive,
  .linkSix,
  .linkSeven,
  .linkEight {
    position: relative;
  }

  .mobSubDownLink {
    padding-left: 0.2rem;
  }

  .mobSubDownLink h5 {
    color: black !important;
    font-family: "Lora";
    font-size: 1.2rem;
  }

  .dOne {
    position: absolute;
    height: 200px;
    width: 216px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
    display: none;
    /* border-radius: 10px; */
    font-size: 0.8rem;
    white-space: nowrap;
    font-weight: bold;
  }

  .SubdOne {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    position: absolute;
    height: 200px;
    width: 216px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
    display: none;
    /* border-radius: 10px; */
    top: 15rem;
    left: 34.3rem;

    font-size: 0.8rem;
    white-space: nowrap;
    font-weight: bold;
  }

  .dOne a {
    color: #505050;
    font-family: Lora;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;

    text-align: center;
    display: flex;
    align-items: center;
  }

  .SubdOne a {
    color: #505050;
    font-family: Lora;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;

    text-align: center;
    display: flex;
    align-items: center;
  }

  .dTwo {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    position: absolute;
    height: 200px;
    width: 216px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
    display: none;
    font-size: 0.8rem;
    white-space: nowrap;
    font-weight: bold;
  }

  .dTwo a {
    color: #505050;
    font-family: Lora;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
  }

  .dThree {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    position: absolute;
    height: 200px;
    width: 216px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
    display: none;
    font-size: 0.8rem;
    white-space: nowrap;
    font-weight: bold;
  }

  .dThree a {
    color: #505050;
    font-family: Lora;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
  }

  .dFour {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    position: absolute;
    height: 200px;
    width: 216px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
    display: none;
    font-size: 0.8rem;
    white-space: nowrap;
    font-weight: bold;
  }

  .dFive {
    position: absolute;
    height: 140px;
    width: 216px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
    display: none;
    font-size: 0.8rem;
    white-space: nowrap;
    font-weight: bold;
  }

  .dFive a {
    color: #505050;
    font-family: Lora;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
  }

  .dSix {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    position: absolute;
    height: 200px;
    width: 216px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
    display: none;
    /* border-radius: 10px; */
    font-size: 0.8rem;
    white-space: nowrap;
    font-weight: bold;
  }

  .dSix a {
    color: #505050;
    font-family: Lora;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
  }

  .dSeven {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    position: absolute;
    height: 150px;
    width: 216px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
    display: none;
    font-size: 0.8rem;
    white-space: nowrap;
    font-weight: bold;
  }

  .dSeven a {
    color: #505050;
    font-family: Lora;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
  }

  .dEight {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-direction: column;
    position: absolute;
    height: 200px;
    width: 216px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 8px 64px 0 rgba(6, 7, 20, 0.13);
    display: none;
    font-size: 0.8rem;
    white-space: nowrap;
    font-weight: bold;
    left: 0vw;
  }

  .dEight a {
    color: #505050;
    font-family: Lora;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
  }

  .login {
    position: relative;
  }

  .login .imgWrapper {
    /* background-color: white; */
    padding: 7px 10px;
    border-radius: 50%;
    margin-top: 10px;
    /* position: relative; */
    margin-left: 1rem;
  }

  .imgWrapper img {
    height: 24px;
    width: 24px;
  }

  .header a:hover {
    color: #573900;
  }

  .searchIcon {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.5);
    margin-top: 0.5rem;
    margin-left: 1rem;
  }

  .langwrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    height: 170px;
    width: 150px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 4rem;
    z-index: 2;
    /* right: 1rem; */
    left: 6.4rem;
    border-radius: 6px;
    display: none;
  }

  .login {
    display: none;
  }

  .langwrapper {
    display: none;
  }

  .left h2 {
    font-size: 0.9rem;
    white-space: nowrap;
  }

  .left {
    flex: 3;
  }

  .right {
    flex: 9;
    /* overflow: auto; */
    height: 100%;
    width: 100%;
    display: flex;

    align-items: center;
  }

  .right li {
    /* font-size: 7px; */
    display: flex;
    align-items: center;
    height: 2vh;
    /* background-color: blue; */
    width: 100%;
    white-space: nowrap;
  }

  /* Marquee adjustment changes for Mobile View Apr2024 - Start */
  .right span {
    /* Initial position off the right side */
    transform: translateX(calc(100% - min(100vw, 100%) + 30px));
    /* Smooth animation for mobile */
    animation: scroll-mobile 30s linear infinite;
  }

  /* Smooth animation for mobile view */
  @keyframes scroll-mobile {
    /* Start from the right side */
    from {
      transform: translateX(calc(100% - min(100vw, 100%) + 30px));
    }
    /* Move to the left */
    to {
      transform: translateX(-100%);
    }
  }
  /* Marquee adjustment changes for Mobile View Apr2024 - End */
}

@media screen and (min-width: 1600px) {
  .header {
    background: linear-gradient(90deg,
        rgba(254, 106, 0, 0.848) 0%,
        rgba(252, 181, 0, 0.634) 100%);

    color: #573900;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* height: 10vh; */
    width: 100%;
    box-sizing: border-box;
    z-index: 2;
    position: relative;
    padding: 0.1rem 0;
  }

  .links {
    display: flex;
    justify-content: space-around;
  }

  .links a {
    padding: 0 0.2rem;
    margin: 0 15px !important;
    font-size: 12px;
  }
}
