.hinduWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* height:300vh; */
  width: 100%;
  box-sizing: border-box;
  padding: 0 6rem;
  padding-bottom: 6rem;
}

.HinduTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
  width: 100%;
  margin: 5rem 0;
}

.HinduTitle img {
  height: 30px;
  width: 30px;
  margin: 0 .5rem 0 1rem;
}

.HinduTitle h1 {
  font-size: 2.5rem;
  font-family: "Lora";
  white-space: normal;
}

.HinduTitle p {
  font-size: 1.2rem;
  line-height: 28px;
  font-family: "Lora";
  text-align: left;
}

.social {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 10vh;
  width: 100%;
  margin-top: 3rem;
}

.socailMediaShare {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.path {
  display: flex;
  width: 100%;

}

.path span {
  margin-left: 10px;
  font-weight: bold;
  font-family: "Lora";
}

.hinduContent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  height: auto;
  width: 100%;

}

.hinduContent input[type="text"] {
  width: 500px;
  /* margin-bottom: 3rem; */
  margin: 3rem 0 !important;
}

.onedropdown {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  /* height: 100vh; */
  width: 100%;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.13); */
}

.hinduContent hr {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.253);
  outline: none;
  border: none;
}

.twodropdown {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  /* height: 100vh; */
  width: 100%;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.13); */
}

.Threedropdown {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  /* height: 100vh; */
  width: 100%;

  /* border-bottom: 1px solid rgba(0, 0, 0, 0.13); */
}

.fourDropdown {
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  /* height: 100vh; */
  width: 100%;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.13); */
}

.hinduContent h1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-family: "Lora";
  height: 7vh;
  width: 100%;
}

.hinduContent h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-family: "Lora";
  height: 7vh;
  width: 100%;
}

.onedropdown h2 {
  color: #c25950;
  font-family: "Lora";
}

.onedropdown p {
  font-size: 1.2rem;
  line-height: 30px;
}

.twodropdown h2 {
  color: #c25950;
  font-family: "Lora";
}

.twodropdown p {
  font-size: 1.2rem;
  line-height: 30px;
}

.Threedropdown h2 {
  color: #c25950;
  font-family: "Lora";
}

.Threedropdown p {
  font-size: 1.2rem;
  line-height: 30px;
}

.fourDropdown h2 {
  color: #c25950;
  font-family: "Lora";
}

.fourDropdown p {
  font-size: 1.2rem;
  line-height: 30px;
}

.commentsWrapper {
  /* height: 70vh; */
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 3rem;
  /* background-color: red; */
}

.commentsWrapper h1 {
  font-size: 2.5rem;
  font-family: "Lora";
}

.comments {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 10vh;
  width: 100%;
}

.comments input {
  height: 60px;
  width: 400px;
  border-radius: 10px;
  outline: none;
  /* border: none; */
  /* border-bottom: 1px solid black; */
  box-sizing: border-box;
  padding-left: 2rem;
}

.comments button {
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 1.3rem;
  font-weight: bold;
  font-family: "Lora, serif";
  border: 2px solid rgba(43, 3, 3, 0.648);
  color: rgb(0, 61, 0);
  height: 60px;
  width: 200px;
  border-radius: 10px;
  font-family: "Lora";
}

.commentsWrapper p {
  font-size: 1.2rem;
  line-height: 35px;
}

.users {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.users p {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.552);
}

.userimg {
  height: 80px;
  width: 80px;
  background-color: rgba(0, 0, 0, 0.097);
  border-radius: 50%;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userDetails {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* height: 5vh; */
  /* background-color: red; */
  position: relative;
  margin-left: 1rem;
}

.userDetails h2 {
  font-family: "Lora";
  margin-top: 2rem;
}

.userDetails p {
  margin-top: -1rem;
  color: rgba(0, 0, 0, 0.234);
}

.reletedCardWrapper {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  margin-top: 2rem;
}

.reletedCardWrapper h2 {
  font-family: "Lora";
}

.releatedHeading {
  width: 100%;
  display: inline-flex;
}

.reletedCard {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}

/*-------------------------------  responsiveness started from here-------------------------------- */

@media screen and (max-width: 1300px) {
  .hinduWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* height:360vh; */
    width: 100%;
    box-sizing: border-box;
    padding: 0 2rem;
    padding-bottom: 4rem;
  }

  .HinduTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    /* flex-direction: column; */
    width: 100%;
    margin: 3rem 0;
  }

  .HinduTitle img {
    height: 20px;
    width: 20px;

  }

  .HinduTitle h1 {
    font-size: 2rem;
    font-family: "Lora";
  }

  .HinduTitle p {
    font-size: 1.1rem;
    line-height: 28px;
    text-align: center;
  }

  .social {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 10vh;
    width: 100%;
    margin-top: 3rem;
  }

  .path {
    display: flex;
  }

  .path span {
    margin-left: 10px;
    font-weight: bold;
  }

  .hinduContent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    /* height: 235vh; */
    width: 100%;
  }

  .hinduContent input[type="text"] {
    width: 500px;
    /* margin-bottom: 3rem; */
  }

  .onedropdown {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    /* height: 150vh; */
    width: 100%;
  }

  .hinduContent hr {
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.253);
    outline: none;
    border: none;
  }

  .twodropdown {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    /* height: 150vh; */
    width: 100%;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.13); */
  }

  .Threedropdown {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    /* height: 150vh; */
    width: 100%;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.13); */
  }

  .fourDropdown {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    /* height: 150vh; */
    width: 100%;
  }

  .hinduContent h1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-family: "Lora";
    height: 5vh;
    width: 100%;
    font-size: 1.5rem;
    margin-top: 1rem;
  }

  .onedropdown h2 {
    color: #c25950;
    font-family: "Lora";
    font-size: 1.2rem;
  }

  .onedropdown p {
    font-size: 1rem;
    line-height: 30px;
  }

  .twodropdown h2 {
    color: #c25950;
    font-family: "Lora, serif";
  }

  .twodropdown p {
    font-size: 1.2rem;
    line-height: 30px;
  }

  .Threedropdown h2 {
    color: #c25950;
    font-family: "Lora, serif";
  }

  .Threedropdown p {
    font-size: 1.2rem;
    line-height: 30px;
  }

  .fourDropdown h2 {
    color: #c25950;
    font-family: "Lora, serif";
  }

  .fourDropdown p {
    font-size: 1.2rem;
    line-height: 30px;
  }

  .commentsWrapper {
    /* height: 80vh; */
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 0rem;
  }

  .commentsWrapper h1 {
    font-size: 1.6rem;
    font-family: "Lora";
  }

  .comments {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10vh;
    width: 100%;
    margin-top: 5rem;
  }

  .comments button {
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: bold;
    font-family: "Lora";
    border: 2px solid rgba(43, 3, 3, 0.648);
    color: rgb(0, 61, 0);
    height: 50px;
    width: 150px;
    border-radius: 10px;
  }

  .commentsWrapper p {
    font-size: 1rem;
    line-height: 28px;
  }

  .users {
    display: flex;
    align-items: center;
    margin-top: 2rem;
  }

  .users p {
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.552);
    font-family: "Lora";
  }

  .userimg {
    height: 50px;
    width: 50px;
    background-color: rgba(0, 0, 0, 0.097);
    border-radius: 50%;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .userDetails {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-left: 1rem;
  }

  .userDetails h2 {
    font-family: "Lora";
    margin-top: 2rem;
    font-size: 1.5rem;
  }

  .userDetails p {
    margin-top: -1rem;
    color: rgba(0, 0, 0, 0.234);
    font-size: 1rem;
  }
}

@media screen and (max-width: 650px) {
  .slugContent {
    font-size: 11px;
  }
  .slugContent img{
    width: 100% !important;
    height: auto !important;
  }
}

@media screen and (max-width: 540px) {
  .slugContent {
    font-size: 15px ! important;
    padding-right: 0px ! important;
  }

  .hinduWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* height:600vh; */
    width: 100%;
    box-sizing: border-box;
    padding: 0 1rem;
    padding-bottom: 4rem;
  }

  .reletedCard {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;

    /* display: flex;
     align-items: flex-start;
     justify-content: flex-start; */
    gap: 1rem;
  }

  .socailMediaShare {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .HinduTitle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 1rem;
  }

  .HinduTitle img {
    height: 16px;
    width: 16px;
    margin: 0 5px 0 4px !important;
  }

  .HinduTitle h1 {
    font-size: .9rem;
    white-space: normal;
    font-family: "Lora";
  }

  .HinduTitle p {
    font-size: 0.8rem;
    line-height: 28px;
    text-align: center;
  }

  .social {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 10vh;
    width: 100%;
    margin-top: 5rem;
  }


  .path {
    display: flex;
  }

  .path span {
    margin-left: 10px;
    font-weight: bold;
  }

  .hinduContent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    /* height: 300vh; */
    width: 100%;
  }

  .hinduContent input[type="text"] {
    width: 300px;
    /* margin-bottom: 3rem; */
  }

  .onedropdown {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    /* height: 200vh; */
    width: 100%;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.13); */
  }

  .hinduContent hr {
    height: 1px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.253);
    outline: none;
    border: none;
  }

  .twodropdown {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    /* height: 150vh; */
    width: 100%;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.13); */
  }

  .Threedropdown {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    /* height: 150vh; */
    width: 100%;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.13); */
  }

  .fourDropdown {
    display: flex;
    flex-direction: column;
    margin-top: 3rem;
    /* height: 150vh; */
    width: 100%;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.13); */
  }

  .hinduContent h1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    font-family: "Lora";
    height: 5vh;
    width: 100%;
    font-size: 1rem;
  }

  .onedropdown h2 {
    color: #c25950;
    font-family: "Lora";
    font-size: 0.9rem;
  }

  .onedropdown p {
    font-size: 0.8rem;
    line-height: 30px;
  }

  .twodropdown h2 {
    color: #c25950;
    font-family: "Lora";
  }

  .twodropdown p {
    font-size: 1.2rem;
    line-height: 30px;
  }

  .Threedropdown h2 {
    color: #c25950;
    font-family: "Lora";
  }

  .Threedropdown p {
    font-size: 1.2rem;
    line-height: 30px;
  }

  .fourDropdown h2 {
    color: #c25950;
    font-family: "Lora";
  }

  .fourDropdown p {
    font-size: 1.2rem;
    line-height: 30px;
  }

  .commentsWrapper {
    /* height: 50vh; */
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 3rem;
  }

  .commentsWrapper h1 {
    font-size: 1.5rem;
    font-family: "Lora";
  }

  .comments {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 10vh;
    width: 100%;
  }

  .comments button {
    outline: none;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    font-weight: bold;
    font-family: "Lora";
    border: 2px solid rgba(43, 3, 3, 0.648);
    color: rgb(0, 61, 0);
    height: 40px;
    width: 140px;
    border-radius: 10px;
  }

  .commentsWrapper p {
    font-size: 1rem;
    line-height: 30px;
  }

  .users {
    display: flex;
    align-items: center;
    margin-top: 2rem;
  }

  .users p {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.552);
  }

  .userimg {
    height: 50px;
    width: 50px;
    background-color: rgba(0, 0, 0, 0.097);
    border-radius: 50%;
    font-size: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .userDetails {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    /* height: 5vh; */
    /* background-color: red; */
    position: relative;
    margin-left: 1rem;
  }

  .userDetails h2 {
    font-family: "Lora";
    margin-top: 2rem;
    font-size: 1rem;
  }

  .userDetails p {
    margin-top: -1rem;
    color: rgba(0, 0, 0, 0.234);
  }
  .slugContent img{
    width: 100% !important;
    height: auto !important;
  }
  
  .slugContent table {
    /* border: 1px solid #d0d0d0; */
    border: none ! important;
    width: inherit;
    /* width: fit-content; */
    /* margin-top: -0.9rem; */
  }
  .slugContent figure {
    width: fit-content !important;
    margin: 0em 6px;
    margin-bottom: 3em;
  }
  .slugContent table caption {
    font-size: 1.3em;
    margin-bottom: 10px;
  }
  
  .slugContent table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .slugContent table tr {
    border-bottom: none;
    display: block;
    margin-bottom: 1px;
    /* margin-top: -0.9em; */
  }
  
  .slugContent table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 1rem;
    /* font-size: .8em; */
    /* text-align: right; */
  }
  
  .slugContent table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .slugContent table td:last-child {
    border-bottom: 1;
  }
  .releatedHeading {
    margin: calc(2rem - 0.14285714em) 0 0rem;
    /* margin-top: -10px; */
  }

  .slugContent table td h3{
    font-size: 11px;
  }

  .slugContent  h2{
    text-align: center;
      font-size: 15px;
  }
  .slugContent  h1{
    text-align: center;
      font-size: 18px;
  }
  .slugContent table td a{
    font-size: 15px;
  }
  .slugContent table td p:last-child {
    font-size: 15px;
  }
  .slugContent table td iframe{
    width:100%;
    margin-top: 10px;
  }

}

@media (min-width: 300px) and (max-width: 440px) {
  .slugContent {
    /* background-color: #c25950; */
    font-size: 1rem;
    padding-right: 0px ! important;
  }
  .slugContent img{
    width: 100% !important;
    height: auto !important;
  }
  .slugContent table {
    border: none ! important;
    width: inherit;
    /* width: fit-content; */
    
    /* margin-top: -0.9rem; */
  }
  .slugContent figure {
    width: fit-content !important;
  }
  .slugContent table caption {
    font-size: 1.3em;
    margin-bottom: 10px;
  }
  
  .slugContent table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .slugContent table tr {
    display: block;
    margin-bottom: 1px;
    /* margin-top: -0.7em; */
    /* background-color: red; */
  }
  
  .slugContent table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: .8em;
    /* text-align: right; */
  }
  
  .slugContent table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .slugContent table td:last-child {
    border-bottom: 0;
  }
  .Slug_HinduTitle__7sQ7u {
    display: flex;
    align-items: center;
    width: 80%;
    box-sizing: border-box;
    margin-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
  }
  .HinduTitle{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 95%;
    margin-bottom: 0px;
  }
  .HinduTitle h1{
    text-align: center;
    font-size: 3vh;
  }
  .slugContent table td {
    font-size: .6rem;
  }

  .slugContent figure {
    margin: 0em 6px;
    margin-bottom: 50px;
  }

  .slugContent table td {
    font-size: 2.5vh;
  }

  .HinduTitle img {
    height: 16px;
    width: 16px;
    margin: 3px  5px 0 4px !important;
}

.releatedHeading {
  margin: calc(2rem - 0.14285714em) 0 0rem;
  /* margin-top: -20px; */
}

.slugContent table td h3{
  font-size: 2.3vh;
}
.slugContent table td:last-child {
  border-bottom: 1px solid #ddd;
}

.slugContent  h2{
  text-align: center;
  font-size: 2.3vh;
}
.slugContent  h1{
  text-align: center;
  font-size: 2.3vh;
}
.slugContent table td a{
  font-size: 2.3vh;
}
.slugContent table td p:last-child {
  font-size: 2.3vh;
}

.slugContent table td iframe{
  width:100%;
  margin-top: 10px;
}
 
}