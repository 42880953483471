.footer {
  /* height: 65vh; */
  width: 100%;
  background-color: var(--footer-bg-color);
  color: var(--text-color);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0;
  padding: 0rem;


}

.footerWrapper{
  padding: 0 10rem;
  box-sizing: border-box;
}

.fContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* height: 50vh; */
  width: 100%;
  padding: 4rem 0;

  
}

.footerWrapper hr{
  outline: none;
  border: none;
  background-color: white;
  height: 2px;
}


.logo{
  height: 70px;
  
}

.lowerthird {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* height: 15vh; */
  /* border-top: 1px solid rgba(255, 255, 255, 0.447); */
  padding: 1.9rem 10rem;
  box-sizing: border-box;
}

.two,
.three,
.four {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.one {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -9rem;
}

.one p {
  color: rgba(250, 247, 247, 0.451);
}

.one a {
  position: relative;
}

.footer a {
  color: var(--text-color);
  text-decoration: none;
  padding: 1rem 0;
  cursor: pointer;
  font-family: 'Lora';
}

.footer h1 {
  color: var(--footer-h1-color);
  font-size: 1.2rem;
  font-family: "Lora";
}

.lowerthird a {
  font-size: 2rem;
  color: var(--footer-h1-color);
  padding: 0 0.7rem;
}

.footerWrapperMobile {
  display: none;
}

/*-------------------------------------------- footer responsiveness----------------------------------------- */

@media screen and (max-width: 540px) {
  .footer {
    display: none;
  }
 .footerWrapperMobile{
  display: flex;
  flex-direction: column;
width: 100%;
height: auto;
background-color: black;
padding-bottom: 2rem;
 }
.footerWrapperMobile a{
  color: rgb(255, 255, 255);
  font-family: 'Lora';
  margin: .7rem 0;
}

.footerWrapperMobile p{
  color: rgba(255, 255, 255, 0.395);
}

.footerWrapperMobile h1{
  font-family: 'Lora';
  font-size: 1rem;
  color: var(--footer-h1-color);
}

.oneMobile img{
  margin: 0 1rem 0 2rem;
}

.oneMobile {
  display: flex;

  width: 100%;
  height: auto;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
}
.ytIcons{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}


.twoMobile {
  display: flex;
  flex-direction: column;
 width: 100%;
 align-items: center;
 margin: 1rem 0;

}
 .threeMobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 1rem 0;

}

.fourMobile {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: 1rem 0;

}


.divWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center !important;
 width: 100%;
 margin: 0;
 }






}
